<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-edit-attributes'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "select-resource-attribute",
              "select-resource-attribute"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName(
                'resources',
                'select-resource-attribute',
                'search'
              )
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section pill"
      v-if="listOfResourceAttributes && listOfResourceAttributes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceAttribute in listOfResourceAttributes"
        :key="resourceAttribute.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectResourceAttribute(resourceAttribute)"
        >
          <span class="label">
            <span class="highlight">{{ resourceAttribute.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="checked(resourceAttribute.id)"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SelectResourceAttributes",
  mixins: [helpOnlineMixin],
  data() {
    return {
      searchQuery: "",
      helpSlug: "resources-resource-attributes"
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceAttributes",
      "selectedResourceAttributes"
    ]),
    listOfResourceAttributes() {
      const query = this.searchQuery;
      if (this.resourceAttributes && this.resourceAttributes.length) {
        if (query && query.length > 1) {
          return this.resourceAttributes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceAttributes;
        }
      }
      return null;
    }
  },
  created() {
    this.getResourceAttributes();
  },
  methods: {
    ...mapActions("resource", [
      "getResourceAttributes",
      "selectResourceAttribute"
    ]),
    checked(id) {
      return (
        this.selectedResourceAttributes &&
        this.selectedResourceAttributes.length &&
        !!this.selectedResourceAttributes.find(item => item.id === id)
      );
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
